/* Full-width inputs */
/* input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
} */

.error {
  color: red;
  font-size: 0.75em;
}
