@import url('https://fonts.googleapis.com/css?family=Libre%20Baskerville:700|Poppins:400');
@tailwind base;

@layer base{
  h1 {
    @apply font-bold text-2xl
  }

  h2{
    @apply font-semibold text-lg
  }

  p{
    @apply text-base py-2
  }

  input {
    @apply border-0 border-b-2 border-black focus:border-acccent inline-block box-border w-full p-1 md:p-2 my-2 outline-none bg-transparent placeholder:text-black
  }
}

@tailwind components;
@tailwind utilities;

.App {
  @apply text-center w-screen h-screen
}

/* Add padding to containers */
.container {
  padding: 18px;
  width: 80%;
  height: 50%;
}

@media only screen and (min-width: 768px) {
  .container {
    width: 30%;
  }
}

.form-container{
  @apply w-full h-full flex flex-col justify-start md:justify-center items-center md:items-start
}

.background {
  @apply bg-gradient-to-br from-primary to-secondary w-full h-full overflow-hidden
}

.dashboard {
  text-align: center;
}

.goals-grid {
  display: inline-block;
  margin: auto;
  text-align: left;
  /* width: 70%; */
}

.goal {
  margin-bottom: 10px;
  min-width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.goal-name {
  @apply inline-block w-1/5 md:w-full text-wrap;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.goal-view {
  @apply inline-block w-1/5 md:w-1/2 text-wrap hover:text-acccent underline
}

.goal-status {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 4px;
}

.status-dot {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 2px;
  margin-left: 2px;
  flex-shrink: 0;
}
.red {
  background-color: #EF5350;
  transition: background-color 0.3s ease;
}  /* Example for red */
.green {
  background-color: #2dd32d;
  transition: background-color 0.3s ease;
}  /* Example for green */
.grey {
  background-color: #808080;
  transition: background-color 0.3s ease;
}  /* Example for grey */
.black {
  background-color: #000000;
  transition: background-color 0.3s ease;
}  /* Example for black */
.blue {
  background-color: #3052c4;
  transition: background-color 0.3s ease;
}  /* Example for black */

.red:hover {
  background-color: #993433;
}

.green:hover {
  background-color: #1b811b;
}

.grey:hover {
  background-color: #3d3d3d;
}

.blue:hover {
  background-color: #243d8f;
}

button {
  @apply px-3 py-1 border-2 border-acccent rounded-md font-semibold hover:bg-acccent hover:text-white duration-300 w-fit
}

.link {
  @apply hover:text-black duration-300 text-acccent font-semibold cursor-pointer
}

.icon-button{
  @apply  w-12 h-12 rounded-full flex items-center justify-center
}

a {
  @apply text-acccent
}
